export const environment = {
	production: false,
	build: {
		id: "1445459634",
		url: "https://gitlab.com/lumosa/portal/portal/-/pipelines/1445459634",
		version: "0.0.56b12679",
		env: "test"
	},
	microsoftAuth: {
		clientId: "6a0449c5-824a-41c8-a368-05eb92bd7f82",
		authority: "https://login.microsoftonline.com/a527d83e-25fa-440f-8590-87e1a866a57a"
	},
	aws: {
		key: "AKIA2KVGEG4UFBEDJDHG",
		secret: "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"
	},
	cpo: {
		token: "f6b66305-1b5c-4d77-ac23-d23ee67e550e",
		url: "https://cpo-test.lumosa.eu"
	},
	api: {
		url: "https://api.chargers-test.lumosa.eu/graphql"
	},
	maps: {
		token: "WEIYQecFQvs5_liCnkZX3CxW31t10tYZbIixG4P0Gzc"
	},
	mailto: {
		finance: "bram@lumosa.eu, paulo@lumosa.eu",
	},
	hubspot: {
		url: "https://app-eu1.hubspot.com",
		instance: "24906865"
	},
	gitlab: {
		caraverdeRpiRepoApiUrl: "https://gitlab.com/api/v4/projects/26356020/repository/files",
		caraverdeRpiRepoApiToken: "glpat-zNPuARDpUBSN_GUNc_Fv"
	},
	grafana: {
		url: "https://lumosa.grafana.net/d/ZekywLF7k/info?orgId=1"
	},
}
